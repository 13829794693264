<template>
    <b-container class="py-4">
        <b-card class="px-3 px-md-5 py-2 py-md-4">
            <router-view />
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>
<script>
export default {
    name: 'ReserveDetailsIndex',
    components: {},
    props: {
        orderId: {
            type: [Number, String],
            default: () => null,
        },
    },
    async mounted() {
        try {
            if (!this.orderId || isNaN(this.orderId)) throw 'NotFound';
            await this.blockingRequest('orders/fetch', this.orderId);
        } catch (error) {
            this.$router.push({
                name: 'NotFound',
            });
        }
    },
};
</script>
<style lang="scss" scoped></style>
